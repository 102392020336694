.tabbed {

    width: 100%;

}

.tabbed .nav {

    margin: 10px 0px 0px 0px;
    height: 50px;
    width: 100%;
    position: relative;

}

.tabbed .nav .tabs {

    width: 100%;
    height: 100%;
    display: flex;
    align-items: stretch;
    justify-content: stretch;
    flex-shrink: 0;
    overflow: hidden;
    border-bottom: 2px solid rgba(255,255,255,0.05);

}

.tabbed .nav .tab {

    padding: 0px 30px;
    display: flex;
    align-items: center;
    position: relative;
    white-space: nowrap;
    color: var(--text-secondary);
    cursor: pointer;
    user-select: none;
    font-size: 14px;
    font-weight: 600;
    transition: color .1s, font-weight .1s;
    flex-grow: 1;
    justify-content: center;

}

.tabbed .nav .tab:hover {

    color: var(--tab-view-hover);

}

.tabbed .nav .tab.selected {

    color: var(--tab-view-selected);

}

.tabbed .nav .indicator {

    position: absolute;
    left: 0px;
    bottom: 0px;
    height: 1px;
    background-color: var(--tab-view-selected);
    transition: left .1s, width .1s;
    transform: translateY(100%);

}

.tabbed.no-transitions .nav .indicator {

    transition: all 0s;

}

.tabbed.no-transitions .nav .tab {

    transition: all 0s;

}