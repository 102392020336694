:root {
    --scrollbar-width: 17px;
}

.sidebar {

    width: 300px;
    position: fixed;
    left: 0px;
    top: 0px;
    height: 100vh;
    background-color: var(--background-primary);
    box-shadow: var(--box-shadow-primary);
    z-index: 200;

}

.sidebar .header {

    width: 100%;
    display: flex;
    height: 80px;
    align-items: center;
    padding: 0px 10px;
    box-sizing: border-box;
    justify-content: center;
    column-gap: 12px;
    white-space: nowrap;

    transition: padding .25s;

}

.sidebar .header .logo {

    width: 28px;
    height: 28px;
    margin-left: -5px;

}

.sidebar .header .title {

    font-size: 19px;
    font-weight: 300;
    margin: 0px 12px 0px 0px;
    color: var(--text-secondary);

}

.sidebar .header .title b {

    font-weight: 600;
    padding: 0px 5px 0px 0px;
    color: var(--text-primary);

}

.sidebar .navigation {

    width: 100%;
    display: flex;
    flex-direction: column;
    height: calc(var(--window-height, 100%) - 80px);
    overflow: auto;

}

.sidebar .navigation .categories {

    width: 100%;
    padding: 0px 20px 40px 20px;
    margin: 10px 0px 0px 0px;
    list-style: none;
    box-sizing: border-box;

}

.sidebar .navigation .categories .category {

    margin: 0px 0px 20px 0px;

}

.sidebar .navigation .categories .category .label {

    font-weight: 600;
    color: var(--text-tertiary);
    text-transform: uppercase;
    font-size: 10px;

}

.sidebar .navigation .categories .category .items {

    width: calc(100% + 40px);
    padding: 4px 0px;
    margin: 0px -20px;
    list-style: none;
    box-sizing: border-box;

}

.sidebar .navigation .categories .category .items .item {

    padding: 10px 20px;
    font-size: 16px;
    font-weight: 500;
    color: var(--text-primary);
    box-sizing: border-box;
    position: relative;
    user-select: none;

}

.sidebar .navigation .categories .category .items .expandable .item svg.expand {

    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    width: 8px;
    fill: transparent;
    stroke: var(--text-secondary);
    stroke-width: 1.5px;
    stroke-linecap: round;

}

.sidebar .navigation .categories .category .items .expandable.expanded .item svg.expand {

    transform: translateY(-50%) rotateZ(90deg);

}

.sidebar .navigation .categories .category .items .item:hover {

    cursor: pointer;

}

.sidebar .navigation .categories .category .items .item::before {

    content: '';
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    z-index: -1;

}

.sidebar .navigation .categories .category .items .item:hover::before {

    background-color: var(--sidebar-item-hover);

}


.sidebar .navigation .categories .category .items .item.selected {

    color: var(--sidebar-selected-tint);

}

.sidebar .navigation .categories .category .items .item.selected::before {

    background-color: var(--sidebar-selected-background);

}

.sidebar .navigation .categories .category .items .expandable .sublist {

    list-style: none;
    padding: 0px;

}

.sidebar .navigation .categories .category .items .expandable li {

    position: relative;
    
}

.sidebar .navigation .categories .category .items .expandable .sublist .item {

    padding: 10px 20px 10px 42px;
    font-size: 15px;
    font-weight: 500;
    color: var(--text-secondary);
    box-sizing: border-box;
    position: relative;
    user-select: none;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

}

.sidebar .navigation .categories .category svg.branch {

    position: absolute;
    left: 20px;
    top: 50%;
    transform: translateY(-50%);
    height: calc(100% + 2px);
    width: 22px;
    fill: transparent;
    stroke: var(--text-tertiary);
    stroke-width: 1px;
    stroke-linecap: round;
    stroke-linejoin: round;

}

.sidebar .burger {

    width: 42px;
    height: 42px;
    margin-left: auto;
    display: none;
    margin-right: calc(-1 * var(--scrollbar-width));

}

.sidebar .search {

    display: flex;
    height: 40px;
    width: calc(100% - 40px);
    margin: 0px 20px 10px 20px;
    background-color: rgba(0, 0, 0, 0.05);
    border-radius: 10px;

}

.sidebar .search .icon-wrapper {

    width: 40px;
    height: 40px;
    min-width: 40px;
    min-height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;

}

.sidebar .search .icon-wrapper svg {

    width: 16px;
    fill: rgba(0, 0, 0, 0.5)
}

.sidebar .search input {

    width: 100%;
    border: 0px solid transparent;
    background-color: transparent;
    outline: none;
    box-sizing: border-box;
    padding: 0px 10px 0px 0px;

}

.sidebar.editable span {
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    z-index: 1000;
    display: block;
    outline: none
}

.sidebar.editable span::after {

    content: '​';
    padding: 0px 0px 0px 20px;

}

.sidebar .navigation .categories .category .categoryHeader {
    position: relative;
}

.sidebar .navigation .categories .category .categoryHeader .editorButtons {
    right: -10px!important;
    position: relative;
}

.sidebar .navigation .categories .category .editorButtons {
    flex-direction: row-reverse;
}

.sidebar .navigation .categories .category .editorButtons div {
    cursor: pointer;
}

.sidebar .navigation .categories .category .categoryHeader.editable .editorButtons,
.sidebar .navigation .categories .item .editorButtons {

    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    min-width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    display: none;
    gap: 5px;

}

.sidebar .navigation .categories .category .categoryHeader.editable .add,
.sidebar .navigation .categories .category .categoryHeader.editable .delete,
.sidebar .navigation .categories .item .add,
.sidebar .navigation .categories .item .delete {

    width: 24px;
    height: 24px;
    background-color: rgba(0,0,0,0.1);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;

}

.sidebar .navigation .categories .category .categoryHeader.editable .delete,
.sidebar .navigation .categories .item .delete {

    background-color: rgba(255,0,0,0.35);

}


.sidebar .navigation .categories .item.editable:hover .editorButtons,
.sidebar .navigation .categories .category .categoryHeader.editable:hover .editorButtons {

    display: flex;

}

.sidebar .navigation .categories .category .categoryHeader.editable .add svg,
.sidebar .navigation .categories .category .categoryHeader.editable .delete svg,
.sidebar .navigation .categories .item.editable .add svg,
.sidebar .navigation .categories .item.editable .delete svg {

    width: 12px!important;
    stroke-width: 1.2!important;
    stroke: var(--text-tertiary)!important;
    position: relative!important;
    left: initial!important;
    top: initial!important;
    right: initial!important;

}

.sidebar .navigation .categories .item.editable .add svg {
    transform: initial!important;
}

.sidebar .navigation .categories .category .categoryHeader.editable .delete svg,
.sidebar .navigation .categories .item.editable .delete svg {

    stroke: rgba(255,0,0,1)!important;
    transform: rotateZ(45deg)!important;

}

.sidebar .navigation .categories .category .categoryHeader.editable:hover .add:hover,
.sidebar .navigation .categories .item.editable:hover .add:hover {

    background-color: rgba(0,0,0,0.15);

}

.sidebar .navigation .categories .category .categoryHeader.editable:active .add:hover,
.sidebar .navigation .categories .item.editable:hover .add:active {

    background-color: rgba(0,0,0,0.2);

}


.sidebar .navigation .categories .category .categoryHeader.editable:hover .delete:hover,
.sidebar .navigation .categories .item.editable:hover .delete:hover {

    background-color: rgba(255,0,0,0.4);

}

.sidebar .navigation .categories .category .categoryHeader.editable:hover .delete:active,
.sidebar .navigation .categories .item.editable:hover .delete:active {

    background-color: rgba(255,0,0,0.45);

}

.sidebar.editable .navigation .categories .item.editable:hover .expand {

    display: none;

}

.sidebar .navigation .content {
    max-height: calc(100% - 50px);
    height: 100%;
    overflow: auto;
}

.sidebar .navigation .category.account {
    display: none;
}

@media (max-width: 900px) {

    .sidebar {

        width: calc(100% - var(--scrollbar-width));
        height: 100%;
        background-color: rgba(0, 0, 0, 0);
        -webkit-backdrop-filter: blur(0px);
        backdrop-filter: blur(0px);
        box-shadow: none;
        pointer-events: none;
        transition: background-color .25s, -webkit-backdrop-filter .25s, backdrop-filter .25s;

    }

    .sidebar.active {

        background-color: rgba(0, 0, 0, 0.5);
        -webkit-backdrop-filter: blur(2px);
        backdrop-filter: blur(2px);
        pointer-events: initial;

    }

    .sidebar .header {

        align-items: center;
        justify-content: flex-start;
        padding: 0px 50px 0px 50px;
        height: 80px;
        background-color: var(--background-secondary);
        pointer-events: initial;
        transition: background-color .25s;

    }

    .sidebar.active .header {

        background-color: var(--background-primary);

    }

    .sidebar .navigation {

        transform: translateX(-100%);
        transition: transform .25s;
        pointer-events: none;
        height: 100%;
        width: 300px;
        background-color: var(--background-primary);

    }

    .sidebar.active {

        height: 100%;

    }

    .sidebar.active .navigation {

        display: flex;
        height: calc(100% - 80px);
        position: absolute;
        bottom: 0px;
        transform: translateX(0%);
        pointer-events: initial;

    }

    .sidebar .burger {

        display: flex;

    }

    .sidebar .navigation .category.account {
        display: block;
    }

}

@media (max-width: 550px) {

    .sidebar .header {

        align-items: center;
        justify-content: flex-start;
        padding: 0px 30px 0px 30px;
        height: 70px;

    }

    .sidebar.active .navigation {

        display: flex;
        height: calc(var(--window-height, 100%) - 70px);
        position: fixed;
        bottom: var(--window-height, 100%) - 100%;
        transform: translateX(0%);

    }

    .sidebar .navigation .items {

        margin: 0px 0px 0px 0px;

    }

    .sidebar .header .title {
        display: none;
    }


}


@media (max-width: 400px) {


    .sidebar.active .navigation {

        width: 100%;

    }

    .sidebar.active {

        background-color: rgba(0, 0, 0, 0);
        -webkit-backdrop-filter: blur(0px);
        backdrop-filter: blur(0px);
        pointer-events: initial;

    }

}

.navigation .content::-webkit-scrollbar {
    display: none;
}

.navigation .content {
    -ms-overflow-style: none;
    scrollbar-width: none;
}