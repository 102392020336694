:root {
    --background-grid-size: 4vh;
}


.container {

    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    background-color: var(--background-secondary);
    padding: 0px 0px 0px 0px;
    box-sizing: border-box;
    overflow: hidden;

}

.container .profile {

    position: fixed;
    right: 30px;
    top: 30px;
    transform: translateY(-30%);

}

.container .content {

    display: flex;
    overflow: auto;
    height: 100%;
    width: 100%;
    padding: 0px;
    flex-direction: column;
    box-sizing: border-box;
    overflow: hidden;
}


.container .branding {

    width: 300px;
    display: flex;
    height: 80px;
    align-items: center;
    padding: 0px 10px;
    box-sizing: border-box;
    justify-content: center;
    column-gap: 12px;
    white-space: nowrap;
    position: absolute;
    left: 0px;
    top: 0px;

    transition: padding .25s;

}

.container .branding .logo {

    width: 28px;
    height: 28px;
    margin-left: -5px;
    fill: var(--background-primary);

}

.container .branding .title {

    font-size: 19px;
    font-weight: 300;
    margin: 0px 12px 0px 0px;
    color: var(--background-secondary);

}

.container .branding .title b {

    font-weight: 600;
    padding: 0px 5px 0px 0px;
    color: var(--background-primary);

}

.container .content .banner {
    width: 100%;
    height: 100vh;
    background-color: var(--background-tertiary);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.container .content .banner .wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    z-index: 10;
    height: 180px;
    position: relative;
    transition: height .25s;
    max-width: 640px;
    padding: 0px 20px;
    width: 100%;
    box-sizing: border-box;
}

.container .content .banner .background {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    pointer-events: none;
    user-select: none;
}

.container .content .banner .background .ground {
    height: calc(100%);
    width: 120vw;

    position: absolute;
    left: -10%;
    bottom: -15%;
    z-index: 3;

    perspective: 60vh;
    perspective-origin: top center;
    transform: translateZ(0);
    mask-image: linear-gradient(180deg, transparent 0%, rgba(0,0,0,1) 100%);
    -webkit-mask-image: linear-gradient(180deg, transparent 0%, rgba(0,0,0,1) 100%);

}

.container .content .banner .background .ground::before {
    content: '';
    display: block;
    width: 70vw;
    height: 60vh;
    position: absolute;
    transform-origin: top center;
    transform: rotateX(75deg) translateZ(0px)  translateX(-50%);;
    backface-visibility: hidden;
    background-image: linear-gradient(to bottom, rgb(25,25,25) 1px, transparent 1px), linear-gradient(to right, rgb(25,25,25) 1px, transparent 1px);
    background-size: 20vh var(--background-grid-size), calc(var(--background-grid-size) * 2) 1px;
    background-position: 0% 0%, center;
    -webkit-animation: move-grid 1.5s linear infinite;
    animation: move-grid 1.5s linear infinite;
    left: 50%;
}

@keyframes move-grid {
    0% {
        background-position: 0% 0px, center;
    }

    100% {
        background-position: 0% var(--background-grid-size), center;
    }
}


.container .content .banner .heading {
    font-size: 32px;
    margin-bottom: 40px;
    margin-top: 0px;
    color: var(--background-primary);
    white-space: nowrap;
}

.container .content .banner .search {

    display: flex;
    height: 50px;
    max-width: 600px;
    margin: 0px 20px 0px 20px;
    background-color: var(--background-primary);
    border-radius: 10px;
    position: relative;
    width: 100%;

}

.container .content .banner .search .icon-wrapper {

    width: 60px;
    height: 50px;
    min-width: 40px;
    min-height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;

}

.container .content .banner .search .loader {

    width: 20px;
    height: 20px;
    stroke: var(--text-secondary);
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
    opacity: 0;
    transform: opacity .25s;

}

.container .content .banner .search.loading .loader {
    opacity: 1;
}

.container .content .banner .search .icon-wrapper svg {

    width: 16px;
    fill: rgba(0, 0, 0, 0.5)
}

.container .content .banner .search input {

    width: 100%;
    border: 0px solid transparent;
    background-color: transparent;
    outline: none;
    box-sizing: border-box;
    padding: 0px 10px 0px 0px;

}

.container .content .banner .results {
    width: calc(100% - 40px);
    border-radius: 10px;
    background-color: var(--background-primary);
    position: absolute;
    top: 175px;
    transform: scale(.8);
    opacity: 0;
    transition: transform .25s, opacity .25s;
}

.container .content .banner .results.visible {
    transform: scale(1);
    opacity: 1;
}

.container .content .banner .results .result {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    padding: 15px 20px;
    box-sizing: border-box;
    border-bottom: 1px solid rgba(0, 0, 0, 0.25);
}

.container .content .banner .results .result:last-of-type {
    border-bottom: none;
}

.container .content .banner .results .result .title {
    font-size: 16px;
    font-weight: 500;
}

.container .content .banner .results .result .subtitle {
    font-size: 13px;
    margin-top: 2.5px;
    color: var(--text-secondary);
}

@media (max-width: 1450px) {

    .left-panel {

        padding: 0px 50px !important;

    }

    .container .content .section .right-panel.first,
    .container .content .section .left-panel.first {
        padding-top: 0px;
    }

    .container .content .section .right-panel.last,
    .container .content .section .left-panel.last {
        padding-bottom: 160px;
    }

}

@media (max-width: 900px) {

    .container .branding {

        width: 240px;

    }

}

@media (max-width: 550px) {

    .container .content .banner .heading {
        margin-bottom: 30px;
        font-size: 28px;
    }

    .container .content .banner .wrapper {
        position: absolute;
        top: 150px;
        padding: 0px 40px;
    }

    .container .content .banner .results {
        width: calc(100% - 80px);
    }

    .container .content .banner .results .result {
        padding: 14px 18px;
    }    

    .container .content .banner .results .result .title {
        font-size: 14px;
        font-weight: 500;
    }
    
    .container .content .banner .results .result .subtitle {
        font-size: 11px;
        margin-top: 2.5px;
        color: var(--text-secondary);
    }

}

@media (max-width: 450px) {

    .container .content .banner .heading {
        margin-bottom: 25px;
        font-size: 20px;
    }

    .container .content .banner .wrapper {
        position: absolute;
        top: 120px;
        padding: 0px 30px;
    }

    .container .branding {
        justify-content: flex-start;
        padding: 0px 0px 0px 15px;
    }

    .container .branding .logo {
        margin-left: 0px;
    }

    .container .branding .title {
        display: none;
    }

    .container .content .banner .results {
        width: calc(100% - 60px);
    }

    .container .content .banner .results .result {
        padding: 14px 18px;
    }    

    .container .content .banner .results .result .title {
        font-size: 14px;
        font-weight: 500;
    }
    
    .container .content .banner .results .result .subtitle {
        font-size: 11px;
        margin-top: 2.5px;
        color: var(--text-secondary);
    }

    .container .profile {

        right: 10px;
    
    }

}

@media (max-width: 450px) {

    .container .content .banner .wrapper {
        padding: 0px 10px;
    }

    .container .content .banner .results {
        width: calc(100% - 20px);
    }

}
