.accountButtons {
    position: absolute;
    right: 30px;
    top: 20px;
    display: flex;
    gap: 10px;
    flex-direction: row-reverse;
}

.profile {

    position: absolute;
    right: 30px;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 50%;
    box-shadow: 0px 0px 5px 0px var(--box-shadow-color);
    z-index: 1000;

}

.profile {

    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: rgba(255,255,255,0.1);
    position: relative;

}

.profile .loader {

    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    height: 40%;
    width: 40%;

}

.profile img {

    width: calc(100% + var(--avatar-border-width) * 2);
    height: calc(100% + var(--avatar-border-width) * 2);
    left: calc(var(--avatar-border-width) * -1);
    top: calc(var(--avatar-border-width) * -1);
    z-index: 10;
    border-radius: 50%;
    position: absolute;
    opacity: 0;
    transform: translate(0px);
    transition: opacity .25s;

}

.profile img.loaded {
    opacity: 1;
} 

.profile:hover {

    cursor: pointer;
    outline: 2px solid var(--tint);

}

.profile.expanded {

    outline: 2px solid var(--tint);

}

.profile.expanded img {

    outline: 2px solid var(--tint);


}

.profile * {

    user-select: none;

}

.profile .options {

    position: absolute;
    right: 0px;
    top: calc(100% + 15px);
    width: 348px;
    min-height: 10px;
    background-color: var(--profile-background);
    padding: 5px 5px 16px 5px;
    border-radius: 16px;
    cursor: initial;
    transform: translateY(10px);
    pointer-events: none;
    opacity: 0;
    box-sizing: border-box;
    
    box-shadow: 0px 0px 19px 0px var(--box-shadow-profile);
    z-index: 1000;

}

.profile.expanded .options {

    transform: translateY(0px);
    opacity: 1;
    transition: transform .15s, opacity .15s;
    pointer-events: initial;

}

.profile .options .body {

    width: calc(100% - 32px);
    border-radius: 0px;
    margin: 0px 16px;
    padding: 0px 0px 0px 0px;
    border-top: 1px solid var(--profile-divider);

}

.profile .current {

    display: flex;
    align-items: center;
    padding: 18px 15px 23px 15px;
    box-sizing: border-box;

}

.profile .current .profile-picture {

    width: 40px;
    height: 40px;

    border-radius: 50%;
    background-position: center center;
    background-size: cover;

    margin: 0px 15px 0px 5px;

}

.profile .current .username {

    font-size: 15px;
    font-weight: 500;

}

.profile .current .email {

    font-size: 13px;
    font-weight: 400;
    
    color: var(--text-secondary);

}

.profile .options .account {

    display: flex;
    align-items: center;
    padding: 12px calc(25px - 15px);
    box-sizing: border-box;
    position: relative;
    cursor: pointer;

}

.profile .options .account * {

    pointer-events: none;

}

.profile .options .account:first-of-type {

    margin-top: 12px;

}

.profile .options .account:last-child {

    margin-bottom: 10px;

}

.profile .options .account::before {

    content: '';
    width: calc(100% + 20px);
    height: 100%;
    border-radius: 10px;
    background-color: transparent;
    position: absolute;
    left: -10px;
    z-index: -1;
    pointer-events: none;
    transition: background-color .1s;

}

.profile .options .account:hover::before {

    background-color: var(--profile-account-hover);

}

.profile .options .account:active::before {

    background-color: var(--profile-account-active);

}

.profile .option .icon-wrapper {

    width: 30px;
    height: 30px;

    border-radius: 50%;
    background-position: center center;
    background-size: cover;

    margin: 0px 20px 0px 0px;

}

.profile  .options .account .username {

    font-size: 14px;
    font-weight: 500;
    margin: 0px 0px 2px 0px;

}

.profile  .options .account .email {

    font-size: 12px;
    font-weight: 400;
    margin: 2px 0px 0px 0px;
    
    color: var(--text-secondary);

}

.profile .actions {

    margin: 10px 0px 0px 0px;
    padding: 12px 0px 0px 0px;  
    border-top: 1px solid var(--profile-divider);

}

.profile.expanded .options {

    display: block;

}

.profile .options .option:hover {

    cursor: pointer;

}

.profile .options .category {

    position: relative;
    height: 30px;
    width: 100%;
    padding: 0px 28px 0px 28px;
    box-sizing: border-box;

}

.profile .options .category .label {

    position: absolute;
    left: 20px;
    top: 50%;
    transform: translateY(-50%);
    color: var(--text-secondary);
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    color: var(--text-primary);

}

.profile .divider {

    position: relative;
    width: 100%;
    box-sizing: border-box;
    border-bottom: 1px solid rgba(0,0,0,0.1);
    margin: 10px 0px;

}

.profile .actions .option {

    display: flex;
    margin: 0px 0px 0px 0px;
    list-style: none;
    white-space: nowrap;
    width: 100%;
    position: relative;
    font-weight: 500;

    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    box-sizing: border-box;
    padding: 12px 12px;
    font-size: 14px;
    user-select: none;
    cursor: pointer;
    transition: background-color .2s;
    color: var(--text-primary);

}

.profile .actions .option svg {

    margin-right: 30px;
    width: 15px;
    height: 15px;

}

.profile .actions .option::before {

    content: '';
    width: calc(100% + 20px);
    height: 100%;
    border-radius: 10px;
    background-color: transparent;
    position: absolute;
    left: -10px;
    z-index: -1;
    pointer-events: none;
    transition: background-color .1s;

}

.profile .actions .option:hover::before {

    background-color: var(--profile-account-hover);

}

.profile .actions .option:active::before {

    background-color: var(--profile-account-active);

}

.profile .option .icon-wrapper {

    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;

}

.profile .option .icon-wrapper svg {

    margin: 0px 0px 0px 0px;

}

.profile .body .add {

    padding: 13px 10px;

}

.profile .body .accounts .add:first-child {

    margin-top: 10px;

}

.profile .body .actions:first-child {

    border-top: 0px solid transparent;
    margin: 0px;

}