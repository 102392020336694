.container {

    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid var(--argument-border);
    border-radius: 7px;
    box-sizing: border-box;
    padding: 3px;
    background-color: var(--background-primary);

}

.container .method {

    padding: 5px 7px;
    border-radius: 5px;
    display: inline-block;
    background-color: var(--article-endpoint-color);
    font-weight: 600;
    letter-spacing: .2px;
    color: var(--background-primary);
    flex: 0;
    font-size: 12px;
    margin: 5px;
}

.container .endpoint {

    padding: 0px 0px 0px 4px;
    display: inline-block;
    border-radius: 0px 5px 5px 0px;
    white-space: nowrap;
    flex: 1;
    font-size: 15px;
    color: var(--text-primary);
    font-weight: 500;
    letter-spacing: .1px;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    
}