.container {

    margin: 40px 0px;
    border: 1px solid var(--code-example-border);
    border-radius: 10px;
    display: flex;
    flex-direction: column;

}

.container .header {

    display: flex;
    padding: 10px;
    box-sizing: border-box;
    border-bottom: 1px solid var(--code-example-border);
    gap: 5px;
    border-radius: 10px 10px 0px 0px;
    background-color: var(--code-example-header-background);
    overflow-x: auto

}

.container .header .item {

    padding: 6px 18px;
    border-radius: 5px;
    font-weight: 400;
    color: var(--code-example-header-item-text);
    font-size: 15px;
    user-select: none;
    white-space: nowrap;

}

.container .header .item:hover {

    background-color: var(--code-example-header-item-hover);
    cursor: pointer;

}

.container .header .item:active {

    background-color: var(--code-example-header-item-active);

}

.container .header .item.selected {

    background-color: var(--code-example-header-item-selected);
    color: var(--code-example-header-item-selected-text);

}

.container .header .item.selected:hover {

    background-color: var(--code-example-header-item-selected-hover);

}

.container .header .item.selected:active {

    background-color: var(--code-example-header-item-selected-active);

}

.container .body .item pre {

    padding: 16px 8px!important;
    margin: 0px!important;
    line-height: 1.5em;
    background-color: transparent!important;
}

.container .body {

    background-color: var(--code-example-body-background);
    position: relative;
    border-radius: 0px 0px 10px 10px;

}

.container .body .item .copy {

    width: 28px;
    height: 28px;
    background-color: var(--code-example-copy-background);
    border-radius: 5px;
    position: absolute;
    right: 5px;
    top: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    transform-origin: top right;
    border: 1px solid var(--code-example-border);
    box-sizing: border-box;

}

.container .body .item .copy:hover {

    cursor: pointer;
    background-color: var(--code-example-copy-background-hover);

}

.container .body .item .copy:active {

    background-color: var(--code-example-copy-background-active);

}

.container .body .item .copy svg {
    
    fill: none;
    stroke: var(--code-example-copy-icon);
    stroke-width: 1px;
    height: 70%;
    pointer-events: none;

}

.container .copy .tooltip {

    white-space: nowrap;
    padding: 8px 14px;
    background-color: var(--tooltip-background);
    color: var(--tooltip-text);
    width: auto;
    font-size: 12px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%) translateY(5px);
    top: calc(100% + 5px);
    border-radius: 7px;
    opacity: 0;
    transition: opacity .2s, transform .2s;
    pointer-events: none;
    user-select: none;
    box-sizing: border-box;

}
.container .copy:hover .tooltip {

    transform: translateX(-50%) translateY(0px);
    opacity: 1;

}