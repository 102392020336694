:root {

    --code-width: 40%;

}

.container {

    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    background-color: var(--background-secondary);
    padding: 0px 0px 0px 300px;
    box-sizing: border-box;
    overflow: hidden;

}

.container .profile {

    position: fixed;
    right: 30px;
    top: 30px;
    transform: translateY(-30%);

}

.container .content {

    display: flex;
    overflow: auto;
    height: 100vh;
    padding: 0px;
    flex-direction: column;
    box-sizing: border-box;
    position: absolute;
    top: 0px;
    width: calc(100% - 300px);
    background-color: var(--background-tertiary);

}

.container .content .section {

    display: flex;
    width: 100%;
    box-sizing: border-box;
    align-items: stretch;

}

.container .content .section .left-panel {

    background-color: var(--background-secondary);
    padding: 0px 100px;
    min-width: calc(100% - var(--code-width));
    flex: 1;
    box-sizing: border-box;
    min-height: max(fit-content, 100%);

}

.container .content .section .left-panel p,
.container .content .section .left-panel li,
.container .content .section td {

    color: var(--article-text-secondary);
    font-weight: 400;

}

.container .content .section .left-panel.loading,
.container .content .section .right-panel.loading {

    height: 100vh;

}

.container .content .section a {

    color: var(--article-link-color);
    font-weight: 400;

}

.container .content .section a:hover {

    text-decoration-line: underline;
    text-decoration-thickness: 1.5px;

}


.container .content .section .right-panel {

    width: var(--code-width);
    box-sizing: border-box;
    padding: 0px 50px;
    color: var(--text-quaternary);
    font-size: 15px;

}

.container .content .section p, .container .content .section a, .container .content .section li {

    white-space: pre-wrap;
    line-height: 1.7em;
    letter-spacing: .1px;
    font-size: 1.05rem;

}

.container .content .section .right-panel pre {

    background-color: rgb(10, 10, 10) !important;
    border-radius: 5px;
    font-weight: 400;
    letter-spacing: .5px;
    font-size: 15px;
    padding: 1em 0px !important;
    line-height: 1.4em;

}

.container .content .section .right-panel pre {

    padding: 0px 1em 0px 0px;

}

.container .content .section h1 {

    font-weight: 600;
    letter-spacing: .5px;
    font-size: 32px;
    margin: 21.44px 0px;
    padding: 0px 0px 0px 0px;
    color: var(--article-text-primary);

}

.container .content .section h3 {

    font-weight: 600;
    letter-spacing: 0.3px;
    font-size: 26px;
    margin: 26.44px 0px;
    padding: 0px 0px 0px 0px;
    color: var(--article-text-primary);

}

.container .content .section h4 {

    margin: 40px 0px -5px 0px;
    font-size: 21px;
    font-weight: 600;

}

.container .content .section .right-panel h3 {

    font-weight: 400;
    letter-spacing: 0.4px;
    color: var(--text-tertiary);

}

.container .content .section .right-panel h4 {

    margin-bottom: 0px;

}

.container .content .section hr {
    border: none;
    height: 1px;
    background-color: var(--divider-color);
    margin: 42px 0px;
}

.container .content table {
    width: 100%;
    border-collapse: collapse;
    background-color: var(--background-primary);
}

.container .content th, .container .content td {
    text-align: left;
}

.container .content th {
    background-color: var(--table-background-odd);
}

.container .content tr:nth-child(odd) {
    background-color: var(--table-background-odd);
}

.container .content tr:nth-child(even) {
    background-color: var(--table-background-even);
}

.container .content table, .container .content th, .container .content td {
    border: 1px solid var(--table-border);
    padding: 7px 8px;
    box-sizing: border-box;
}

.container .content ul {
    padding: 0px 0px 0px 20px;
}

.container .content li {
    padding: 0px 0px 5px 0px;
}

.container .content li::marker {
    font-size: 1.1em;
}

.container .content .left-panel p code {
    font-family: monospace;
    font-weight: 600;
    position: relative;
    padding: 3px 7px;
    background-color: var(--article-code-background);
    border-radius: 5px;
    border: 1px solid var(--article-code-border);
    white-space: nowrap;
}

.container .content .section .right-panel.first,
.container .content .section .left-panel.first {
    padding-top: 60px;
}

.container .content .section .right-panel.last,
.container .content .section .left-panel.last {
    padding-bottom: 160px;
}

.container .content .section .right-panel > *,
.container .content .section .left-panel > * {
    animation: load .1s ease-in-out forwards;
}

@keyframes load {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.container .content .section .right-panel.last,
.container .content .section .left-panel.last {
    padding-bottom: 160px;
}

.container .content .section .right-panel h4.h3 {

    margin: 28px 0px -5px 0px;

}

@media (max-width: 1450px) {

    .right-panel {

        padding: 0px 50px !important;

    }

    .left-panel {

        padding: 0px 50px 0px 50px!important;

    }

    .container .content .section .left-panel.first {
        padding-top: 60px!important;
    }

    .container .content .section .right-panel.last,
    .container .content .section .left-panel.last {
        padding-bottom: 160px;
    }

}

@media (max-width: 1200px) {

    .container .content {
        background-color: var(--background-secondary);
    }

    .container .content .right-panel {
        background-color: var(--background-tertiary);
    }

    .content .section {

        flex-direction: column !important;

    }

    .content .section .left-panel,
    .content .section .right-panel {

        width: 100% !important;

    }

    .container .content .left-panel hr:last-child {
        background-color: transparent;
        margin: 20px 0px;
    }

    .container .content .section .left-panel {
        padding-top: 20px!important;
    }

    .container .content .section .left-panel.first {
        padding-top: 50px!important;
    }

    .container .content .section .left-panel.last {
        padding-bottom: 50px!important;
    }

    .container .content .section .right-panel.last:empty {
        display: none;
    }

}

@media (max-width: 900px) {

    .container {

        padding: 0px 0px 0px 0px;

    }

    .container .content {

        padding: 80px 0px 0px 0px;
        width: 100%;

    }

    .content .section .left-panel,
    .content .section .right-panel {

        padding: 0px 80px !important;

    }

    .container .content .section .right-panel:not(:empty) {
        padding-bottom: 15px!important;
    }

    .container .content .section .left-panel.first {
        padding-top: 10px!important;
    }

    .container .profile {

        display: none;
    
    }

}

@media (max-width: 550px) {

    .container {

        padding: 0px 0px 0px 0px;

    }

    .container .content {

        padding: 70px 0px 0px 0px;

    }

    .content .section .left-panel,
    .content .section .right-panel {

        padding: 0px 20px 0px 20px !important;

    }

    .content .section .left-panel.last {

        padding-bottom: 80px!important;

    }

    .container .profile {

        right: 40px;
        top: 35px;
        transform: translateY(-50%);
    
    }

}

.container .content.transition-enter {
    opacity: 1;
    transform: translateY(30px);
}

.container .content.transition-enter-active {
    opacity: 1;
    transform: translateY(0px);
    transition: transform .3s, opacity .3s;
    transition-delay: .05s;
    pointer-events: none;
}

.container .content.transition-exit {
    opacity: 1;
    transform: translateY(0px);
}

.container .content.transition-exit-active {
    opacity: 1;
    transform: translateY(-30px);
    transition: transform .15s, opacity .15s;
    pointer-events: none;
}