:root {

    --tint: rgb(35, 121, 235);

    --text-primary: rgb(0,0,0);
    --text-secondary: rgb(100,100,100);
    --text-tertiary: rgb(170,170,170);
    --text-quaternary: rgb(130,130,130);

    --background-primary: rgb(255,255,255);
    --background-secondary: rgb(246,246,246);
    --background-tertiary: rgb(10, 10, 10);

    --button-primary: rgb(35, 121, 235);
    --button-primary-hover: rgb(36, 110, 209);
    --button-primary-active: rgb(28, 94, 184);
    --button-primary-text: rgb(255,255,255);

    --button-secondary: rgb(236, 236, 236);
    --button-secondary-hover: rgb(216, 216, 216);
    --button-secondary-active: rgb(200, 200, 200);
    --button-secondary-text: rgb(50,50, 50);

    --button-danger: rgb(216, 60, 62);
    --button-danger-hover: var(--button-danger);
    --button-danger-active: rgb(192, 53, 55);
    --button-danger-text: rgb(255,255,255);

    --input-border: rgb(236,236,236);
    --input-placeholder: rgb(150,150,150);
    --input-focused: rgb(35,121,235);

    --checkbox-border: rgb(226,226,226);
    --checkbox-background: var(--background-primary);
    --checkbox-enabled: var(--input-focused);

    --qr-login-hover: rgba(0,0,0,0.1);
    --qr-login-active: rgba(0,0,0,0.15);

    --qr-login-background: rgb(255,255,255);
    --qr-login-border: rgb(205,205,205);

    --tooltip-background: rgb(75,75,75);
    --tooltip-text: rgb(255,255,255);

    --avatar-border-width: 2px;

    --box-shadow-profile: rgba(0,0,0,0.25);
    --profile-background: rgb(255,255,255);
    --profile-account-hover: rgba(0,0,0,0.05);
    --profile-account-active: rgba(0,0,0,0.1);
    --profile-divider: rgba(0,0,0,0.1);

    --sidebar-selected-tint: rgb(35, 121, 235, 1);
    --sidebar-selected-background: rgba(35, 121, 235, 0.1);
    --sidebar-selected-hover: rgba(35, 121, 235, 0.2);
    --sidebar-selected-active: rgba(35, 121, 235, 0.25);
    --sidebar-item-hover: rgba(0,0,0,0.05);
    --sidebar-item-active: rgba(0,0,0,0.1);

    --box-shadow-primary: 0px 0px 4px 0px rgba(0,0,0,0.15);

    --border-primary: rgba(0,0,0,0.1);

    --tab-view-selected: rgb(255,255,255);
    --tab-view-hover: rgb(150,150,150);
    --tab-view-text: rgb(255,255,255);

    --divider-color: rgb(220,220,220);

    --article-text-primary: var(--text-primary);
    --article-text-secondary: rgb(35, 35, 35);

    --article-link-color: var(--button-primary);

    --table-border: rgba(0,0,0,0.1);
    --table-background-even: rgba(0,0,0,0.03);
    --table-background-odd: rgba(0,0,0,0);

    --article-code-background: rgba(0,0,0,0.05);
    --article-code-border: rgba(0,0,0,0.1);

    --article-endpoint-color: var(--tint);

    --argument-border: rgba(0,0,0,0.1);

    --notice-warning-background: rgb(255, 250, 245);
    --notice-warning-icon-background: rgb(215, 155, 0);
    --notice-warning-border: rgb(215, 155, 0);

    --code-example-border: var(--table-border);
    --code-example-header-background: var(--background-primary);
    --code-example-header-item-text: var(--text-secondary);
    --code-example-header-item-hover: var(--sidebar-item-hover);
    --code-example-header-item-active: var(--sidebar-item-active);
    --code-example-header-item-selected: var(--sidebar-selected-background);
    --code-example-header-item-selected-hover: var(--sidebar-selected-hover);
    --code-example-header-item-selected-active: var(--sidebar-selected-active);
    --code-example-header-item-selected-text: var(--tint);
    --code-example-body-background: var(--background-primary);
    --code-example-copy-background: rgb(240,240,240);
    --code-example-copy-background-hover: rgb(210,210,210);
    --code-example-copy-background-active: rgb(200,200,200);
    --code-example-copy-icon: rgb(140,140,140);


}