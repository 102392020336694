.container {

    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid var(--argument-border);
    background-color: var(--background-primary);
    border-radius: 7px;
    box-sizing: border-box;
    padding: 20px;
    margin-top: 10px;

}

.container .details {

    display: flex;
    flex-direction: column;
    gap: 6px;

}

.container .details .title {

    font-size: 16px;

}

.container .details .title .name {

    font-weight: 500;

}

.container .details .title .divider {

    min-height: 100%;
    padding: 0px 10px;
    display: inline;
    position: relative;

}

.container .details .title .divider::after {

    content: '';
    width: 3px;
    height: 3px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    background-color: var(--text-secondary);

}

.container .details .title .type {

    font-weight: 500;
    color: var(--text-secondary);

}

.container .details .subtitle {

    font-size: 16px;
    color: var(--text-secondary)

}

.container .required {

    background-color: var(--tint);
    color: var(--background-secondary);
    padding: 4px 9px;
    border-radius: 20px;
    font-size: 12px;
    font-weight: 500;

}

.container .required::after {

    content: 'Required'

}