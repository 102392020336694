button {
    outline: none;
    background-color: transparent;
    border: 0px solid transparent;
}

html {

    height: -webkit-fill-available;
    min-height: stretch;
    min-height: -webkit-fill-available;
    min-height: -moz-available;

}

body, html {

    margin: 0px;
    padding: 0px;
    height: 100%;
    min-height: stretch;
    min-height: -webkit-fill-available;
    min-height: -moz-available;

}

body, html * {

    font-family: 'Rubik', sans-serif;

}

a {

    text-decoration: none;
    -webkit-user-drag: none;

}